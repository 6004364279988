.gallery {
    @include make-row();
    margin: $gallery-margin;
    justify-content: center;
  
    @include media-breakpoint-up(sm) {
      margin: $gallery-margin_sm;
    }
  
    .gallery-thumbs {
      @include media-breakpoint-up(lg) {
        @include make-col(6);
      }
      display: flex;
      flex-wrap: wrap;  
    
    }

    .gallery-item {
      @include make-col-ready();
      @include make-col(6);
      margin: $gallery-item-margin;
      padding: $gallery-item-padding;
  
      transition: $transition-base;
    
      @include media-breakpoint-up(sm) {
        margin: $gallery-item-margin_sm;
        padding: $gallery-item-padding_sm;
      }
    
  
      .gallery-link {
        position: relative;
        @extend .d-block;
        overflow: hidden;
      }
  
      .gallery-img {
        @extend .img-fluid;
        @extend .w-100;
        transition: 0.5s ease-in-out;
      }
  
      .gallery-caption {
        @extend .d-none;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
  
        @extend .align-items-center;
        @extend .justify-content-center;
  
        background: $gallery-caption-background;
        font-size: $gallery-caption-font_size;
        color: $gallery-caption-color;
  
        &::before {
          content: '+';
        }
  
        @include media-breakpoint-up(sm) {
          font-size: $gallery-caption-font_size_sm;
        }
      }
  
      &.large-item {
        @include make-col(12);
        @include media-breakpoint-up(lg) {
          @include make-col(6);
        }
      }
  
      &.last-item {
        .gallery-caption {
          @extend .d-flex;
          font-size: 40px;
          font-family: $font-family-lobster;
        }
      }
      &:hover {
        .gallery-img {
            transform: scale(1.05);
        }
      }
    }
  
    .gallery-hidden {
      @extend .d-none;
    }
  }