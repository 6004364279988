&.accommodation_overview,
&.accommodation_category_overview,
&.accommodation_category_detail {

	.content {
		@include media-breakpoint-only(xs) {
            padding-left: 5px;
            padding-right: 5px;
        }
        @include media-breakpoint-only(sm) {
            padding-left: 10px;
            padding-right: 10px;
        }
        @include media-breakpoint-only(xl) {
            padding-left: 25px;
            padding-right: 25px;
        }
        .container-fluid {
            @include media-breakpoint-down(sm) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .grid {
            @include media-breakpoint-only(xs) {
                margin: 0 0 -5px 0;
            }
            @include media-breakpoint-only(sm) {
                margin: 0 -5px -10px -5px;
            }
            @include media-breakpoint-only(xl) {
                margin: 0 -20px -40px -20px;
            }
            .item {
                @include media-breakpoint-only(xs) {
                    margin-bottom: 5px;
                    padding-left: 0;
                    padding-right: 0;
                }
                @include media-breakpoint-only(sm) {
                    margin-bottom: 10px;
                    padding-left: 5px;
                    padding-right: 5px;
                }
                @include media-breakpoint-only(xl) {
                    margin-bottom: 40px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(4);
                }
				.card {
                    .card-image {
                        .card-image-link {
                            overflow: hidden;
                            .card-img {
                                transition: 0.5s ease-in-out;
                            }
                            &:hover {
                                .card-img {
                                    transform: scale(1.05);
                                }
                            }
                        }
                    }
					.card-body {
						background-color: $primary;
						.card-caption {
							.card-title-link,
							.card-title,
							.card-subtitle,
							.card-description {
								color: $white;
							}
						}
						.card-buttons {
							.card-btn {
								width: auto;
								@extend .btn-transparent;
							}
						}
					}
				}
			}
		}
	}

}

&.accommodation_category_detail {

    /* info */
	.info {
		margin-bottom: 6vh;
		text-align: center;
    }

}

&.accommodation_detail {

	.content {
		> .container-fluid {
			padding: 0 !important;
		}
	}

    /* info */
	.info {
		margin-bottom: 6vh;
		text-align: center;
        .accommodation-properties {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 0 0 10px 0;
            padding: 0;
            list-style: none;
            &__item { margin: 0 20px 0 0;}
            &__icon {
                margin: 0 10px 0 0;
            }
            &__label {
                color: $body-color;
            }
        }
    }

    /* gallery */
    .gallery {
		margin-bottom: 6vh;
        @include media-breakpoint-up(xl) {
            padding: 0 25px
        }
	}

    /* description */
    .description {
        max-width: 768px;
        margin-bottom: 3vh;
    }

    /* characteristics */
    .characteristics {
        .list {
            @include make-row();
            .list-item {
                @include make-col-ready();
                @include make-col(12);
                @include media-breakpoint-up(sm) {
                    @include make-col(6);
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(4);
                }
                margin-top: 30px;
                .list-item-title {
                    color: $gray-dark;
                }
                .sub-list {
                    @extend ul.custom-list;
                }
            }
        }
	}

	.content-bottom {
		margin-top: 30px;
	}

}


&.accommodation_detail {

    .w3-filter-collection {
        margin: 30px 0;
        justify-content: center;
        .filters {
            margin: 0 !important;
            padding: 15px;
            background-color: $white;
            border-radius: 8px;
        }
    }

}
