.footer {
    position: relative;
    margin-top: 100px;
    padding-top: 145px;
    color: $white;
    background: url('/images/footer-curve.png') top center no-repeat $primary;
    font-size: 14px;
    line-height: 1.75;
    @include media-breakpoint-only(xs) {
        padding-top: 200px;
    }
    h1, h2, h3, h4, h5, h6 {
        color: $white;
    }

/*  footer-outro
    ========================================================================== */
    .footer-outro {
        margin: 30px 0;
        .container-holder {
            position: relative;
        }

        /* footer-logo */
        .footer-logo {
            @include media-breakpoint-down(md) {
                max-width: 270px;
            }
            a {
                @extend .d-block;
            }
        }

        /* park_footer_badge */
        .park_footer_badge {
            z-index: 100;
            position: absolute;
            bottom: 100%;
            @include media-breakpoint-only(xs) {
                left: 0;
                right: 0;
                margin-bottom: 30px;
            }
            @include media-breakpoint-up(sm) {
                right: 30px;
                bottom: 0;
            }
            .park-footer-badge-link {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: $white;
                width: 165px;
                height: 165px;
                margin: 0 auto;
                padding: 20px;
                border-radius: 100%;
                background-color: $secondary;
                transform: rotate(5deg);
                text-align: center;
                text-decoration: none;
                .park-footer-badge-title {
                    margin-bottom: 10px;
                    font-size: 24px;
                    line-height: 1;
                    font-family: $font-family-lobster;
                }
                .park-footer-badge-sub-title {
                    font-size: 11px;
                    line-height: 1.2;
                    text-transform: uppercase;
                }
                &:hover {
                    background-color: $tertiary;
                    transform: rotate(10deg);
                }
            }

        }

        /* footer_text */
        .footer_text {
            a {
                color: $white;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

/*  footer-sitelinks
    ========================================================================== */
    .footer-sitelinks {

        /* footer_logolink */
        .footer_logolink {
			margin-bottom: 30px;

			.list {
                display: flex;

                .list-item {
                    margin-right: 0;
                    line-height: 1;

                    + li {
                        margin-left: 15px;
                    }

                    .link {
                        color: $white;
                        font-size: 30px;

                        &:hover {
                            color: $secondary;
                        }
                    }
                }
            }
        }

        .column.one {
            @include make-col(12);
            padding-right: 15px !important;
            @include media-breakpoint-up(xl) {
                @include make-col(5);
            }
        }
        .column.two {
            @include make-col(12);
            @include media-breakpoint-down(lg) {
                padding-left: 15px !important;
            }
            @include media-breakpoint-up(xl) {
                @include make-col(7);
            }
            @include make-row;
            margin-left: 0;
            margin-right: 0;

            /* footer_text */
            .footer_text {
                @include media-breakpoint-down(md) {
                    @include make-col(6);
                }
                @include make-col(4);
                margin-bottom: 30px;
                h5, h6 {
                    margin-bottom: 1rem;
                    font-size: 16px;
                    text-transform: uppercase;
                }
                a {
                    color: $white;
                    text-decoration: none;
                    &:hover {
                        text-decoration: underline;
                    }
                }
                ul {
                    margin: 0;
                    padding: 0;
                    list-style: none;
                }
                &:first-of-type {
                    a {
                        text-decoration: underline;
                    }
                }
                &:last-of-type {
                    margin-bottom: 15px;
                    @include make-col(12);
                    ul {
                        display: flex;
                        align-items: center;
                        flex-direction: row;
                        li {
                            margin: 0 20px 20px 0;
                        }
                    }
                }
            }
        }
    }

/*  footer-copyright
    ========================================================================== */
    .footer-copyright {
        padding: 15px 0;
        background-color: $gray-dark;

        /* footer_link */
        .footer_link {
            .list {
                .list-item {
                    margin-right: 30px;
                    font-weight: 400;
                    .link {
                        color: $white;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }

                    }
                    &:first-child {
                        pointer-events: none;
                    }
                }
            }
        }
    }

}
