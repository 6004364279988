/*  usps-section
    ========================================================================== */
.usps-section {
    padding-top: 30px;
    @include media-breakpoint-up(md) {
        padding-top: 15px;
    }
    ol {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        @include media-breakpoint-up(md) {
            flex-direction: row;
        }
        margin: 0;
        padding: 0;
        list-style: none;
        text-align: center;
        li {
            min-height: 24px;
            margin: 5px 15px;
            font-size: 15px;
            font-weight: 400;
            font-style: italic;
            @include media-breakpoint-up(md) {
                margin: 10px 30px;
            }
            &::before {
                content: "";
                float: left;
                width: 28px;
                height: 24px;
                margin-right: 10px;
                background-image: url("/images/list-icon.svg");
            }
            a {
                color: $white;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}
&.park-de-bergen {
    .usps-section {
        ol {
            li {
                &::before {
                    background-image: url("/images/list-icon-debergen.svg");
                }
            }
        }
    }
}
&.park-eldorado {
    .usps-section {
        ol {
            li {
                &::before {
                    background-image: url("/images/list-icon-eldorado.svg");
                }
            }
        }
    }
}
&.park-klein-canada {
    .usps-section {
        ol {
            li {
                &::before {
                    background-image: url("/images/list-icon-kleincanada.svg");
                }
            }
        }
    }
}
&.park-de-spaendershorst {
    .usps-section {
        ol {
            li {
                &::before {
                    background-image: url("/images/list-icon-despaendershorst.svg");
                }
            }
        }
    }
}
&.park-de-maasterp {
    .usps-section {
        ol {
            li {
                &::before {
                    background-image: url("/images/list-icon-demaasterp.svg");
                }
            }
        }
    }
}
&.park-hoeve-de-schaaf {
    .usps-section {
        ul {
            li {
                &::before {
                    background-image: url("/images/list-icon-hoevedeschaaf.svg");
                }
            }
        }
    }
}
&.park-klein-paradijs {
    .usps-section {
        ul {
            li {
                &::before {
                    background-image: url("/images/list-icon-kleinparadijs.svg");
                }
            }
        }
    }
}
&.park-de-grote-altena {
    .usps-section {
        ul {
            li {
                &::before {
                    background-image: url("/images/list-icon-degrotealtena.svg");
                }
            }
        }
    }
}

/*  intro-section
    ========================================================================== */
.intro-section {
    margin: 6vh 0;
    text-align: center;
    .container-holder {
        max-width: 840px;
        margin: 0 auto;
        h1 {
            margin-bottom: 1rem;
        }
        h2,
        p:last-of-type {
            margin-bottom: 0;
        }
    }
}

/*  highlights-section
    ========================================================================== */
.highlights-section {
    .grid {
        .item {
            @include media-breakpoint-up(sm) {
                flex: 1;
                max-width: 130px;
            }
            @include media-breakpoint-only(xs) {
                @include make-col(6);
            }
            .card {
                text-decoration: none;
                .card-image {
                    width: 100px;
                    margin: 0 auto;
                    font-size: unset;
                    background-color: $secondary;
                    transition: 0.5s;
                }
                .card-body {
                    padding: 0;
                    .card-caption {
                        text-align: center;
                        .card-title {
                            margin-bottom: 0;
                            color: $gray-dark;
                            font-size: 13px;
                            text-align: center;
                        }
                    }
                }
                &:hover {
                    .card-image {
                        background-color: $primary;
                    }
                }
            }
        }
    }
}

/*  bundles
    ========================================================================== */
&.service_overview .content,
&.service_category .content,
&.service_detail .content,
&.blog_post_overview .content,
&.blog_post_detail .content {
    @include media-breakpoint-only(xs) {
        padding-left: 5px;
        padding-right: 5px;
    }
    @include media-breakpoint-only(sm) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @include media-breakpoint-only(xl) {
        padding-left: 25px;
        padding-right: 25px;
    }
    .container-fluid {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .grid {
        @include media-breakpoint-only(xs) {
            margin: 0 0 -5px 0;
        }
        @include media-breakpoint-only(sm) {
            margin: 0 -5px -10px -5px;
        }
        @include media-breakpoint-only(xl) {
            margin: 0 -20px -40px -20px;
        }
        .item {
            @include media-breakpoint-only(xs) {
                margin-bottom: 5px;
                padding-left: 0;
                padding-right: 0;
            }
            @include media-breakpoint-only(sm) {
                margin-bottom: 10px;
                padding-left: 5px;
                padding-right: 5px;
            }
            @include media-breakpoint-only(xl) {
                margin-bottom: 40px;
                padding-left: 20px;
                padding-right: 20px;
            }
            @include media-breakpoint-up(lg) {
                @include make-col(4);
            }
            .card {
                .card-image {
                    overflow: hidden;
                    .card-img {
                        transition: 0.5s ease-in-out;
                    }
                }
                .card-body {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    justify-content: flex-end;
                    background-color: rgba($black, 0.15);
                    text-align: center;
                    .card-caption {
                        flex: 0;
                        .card-title-link,
                        .card-title {
                            margin-bottom: 0;
                            color: $white;
                        }
                        .card-subtitle,
                        .card-description {
                            @extend .d-none;
                        }
                    }
                    .card-buttons {
                        @extend .d-none;
                    }
                }
                &:hover {
                    .card-img {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
}

/*  tiles-section
    ========================================================================== */
.tiles-section {
    @include media-breakpoint-only(xs) {
        padding-left: 5px;
        padding-right: 5px;
    }
    @include media-breakpoint-only(sm) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @include media-breakpoint-only(xl) {
        padding-left: 25px;
        padding-right: 25px;
    }
    .container-fluid {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    h2,
    p {
        margin-bottom: 0;
        text-align: center;
    }
    .grid {
        @include media-breakpoint-only(xs) {
            margin: 0 0 -5px 0;
        }
        @include media-breakpoint-only(sm) {
            margin: 0 -5px -10px -5px;
        }
        @include media-breakpoint-only(xl) {
            margin: 0 -20px -40px -20px;
        }
        margin-top: 40px !important;
        .item {
            @include media-breakpoint-only(xs) {
                margin-bottom: 5px;
                padding-left: 0;
                padding-right: 0;
            }
            @include media-breakpoint-only(sm) {
                margin-bottom: 10px;
                padding-left: 5px;
                padding-right: 5px;
            }
            @include media-breakpoint-only(xl) {
                margin-bottom: 40px;
                padding-left: 20px;
                padding-right: 20px;
            }
            @include media-breakpoint-up(lg) {
                @include make-col(4);
            }
            .card {
                .card-image {
                    overflow: hidden;
                    .card-img {
                        transition: 0.5s ease-in-out;
                    }
                }
                .card-body {
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    display: flex;
                    padding: 20px;
                    justify-content: flex-end;
                    background-color: rgba($black, 0.15);
                    text-align: center;
                    .card-caption {
                        flex: 0;
                        .card-title-link,
                        .card-title {
                            color: $white;
                        }
                        .card-subtitle {
                            display: inline-block;
                            margin: 0 auto 0 0;
                            padding: 10px 20px;
                            color: $white;
                            font-size: 14px;
                            font-weight: 700;
                            border-radius: 2px;
                            background-color: $tertiary;
                            transition: background-color 0.5s;
                            &:hover {
                                background-color: $primary;
                            }
                        }
                        .card-description {
                            @extend .d-none;
                        }
                    }
                    .card-buttons {
                        @extend .d-none;
                    }
                }
                &:hover {
                    .card-img {
                        transform: scale(1.05);
                    }
                }
            }
        }
    }
}

/*  deals-section
    ========================================================================== */
.deals-section {
    margin: 6vh 0;
    padding: 6vh 0;
    background-color: $secondary;
    text-align: center;
    .container {
        padding: 0;
    }
    h2 {
        color: $white;
    }
    .owl-carousel {
        margin: 40px 0;
        .item {
            .card {
                .card-body {
                    padding: 20px;
                    .card-caption {
                        .card-subtitle {
                            color: $gray-dark;
                        }
                    }
                }
            }
        }
    }
}
&.main {
    .deals-section {
        background-color: $primary;
    }
}

/*  parks-overview-section
    ========================================================================== */
.parks-overview-section {
    margin: 6vh 0;
    @include media-breakpoint-only(xs) {
        padding-left: 5px;
        padding-right: 5px;
    }
    @include media-breakpoint-only(sm) {
        padding-left: 10px;
        padding-right: 10px;
    }
    @include media-breakpoint-only(xl) {
        padding-left: 25px;
        padding-right: 25px;
    }
    .container-fluid {
        @include media-breakpoint-down(sm) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    h2 {
        margin-bottom: 40px;
        text-align: center;
    }
    .grid {
        @include media-breakpoint-only(xs) {
            margin: 0 0 -5px 0;
        }
        @include media-breakpoint-only(sm) {
            margin: 0 -5px -10px -5px;
        }
        @include media-breakpoint-only(xl) {
            margin: 0 -20px -40px -20px;
        }
        .item {
            @include media-breakpoint-only(xs) {
                margin-bottom: 5px;
                padding-left: 0;
                padding-right: 0;
            }
            @include media-breakpoint-only(sm) {
                margin-bottom: 10px;
                padding-left: 5px;
                padding-right: 5px;
            }
            @include media-breakpoint-only(xl) {
                margin-bottom: 40px;
                padding-left: 20px;
                padding-right: 20px;
            }
            @include media-breakpoint-up(lg) {
                @include make-col(6);
            }
            @include media-breakpoint-up(xl) {
                @include make-col(4);
            }
            .card {
                background-color: $gray;
                .card-image {
                    background: inherit;
                    .park-logo {
                        position: absolute;
                        right: 15px;
                        bottom: -30px;
                        width: 100px;
                        @include media-breakpoint-up(lg) {
                            width: 150px;
                            right: 40px;
                            bottom: -40px;
                        }
                    }
                }
                .card-body {
                    background: inherit;
                    @include media-breakpoint-up(lg) {
                        padding: 40px;
                    }
                    .card-caption {
                        @include media-breakpoint-up(lg) {
                            //padding-right: 165px; for park-map-image
                        }
                        .card-subtitle {
                            color: $white;
                        }
                        .card-title-link {
                            color: $white;
                            .card-title {
                                @include media-breakpoint-up(xl) {
                                    max-width: 300px;
                                }
                                font-size: 18px;
                            }
                        }
                        .card-description {
                            margin: 0;
                            @include media-breakpoint-up(xl) {
                                margin-top: 30px;
                            }
                            color: $white;
                            ol {
                                @extend ol.custom-list;
                                li {
                                    font-weight: 400;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                    .card-properties {
                        display: none;
                        @include media-breakpoint-up(xl) {
                            position: absolute;
                            top: 30px;
                            right: 30px;
                        }
                        ul {
                            display: flex;
                            margin: 0 0 0 -15px;
                            li {
                                max-width: 75px;
                                margin-left: 15px;
                                padding: 0;
                                text-align: center;
                                .icon {
                                    position: static !important;
                                    max-width: 40px;
                                    margin: 0 auto;
                                }
                                .label {
                                    margin-top: 5px;
                                    color: $white;
                                    font-size: 10px;
                                    font-weight: 700;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                    .park-map-image {
                        display: none; // hide
                        @include media-breakpoint-up(lg) {
                            position: absolute;
                            right: 40px;
                            bottom: 40px;
                        }
                        max-width: 165px;
                        max-height: 190px;
                    }
                    .card-buttons {
                        .card-btn {
                            width: auto;
                            @extend .btn-transparent;
                        }
                    }
                }
            }
        }

        /* De Bergen */
        .item:nth-child(1) {
            .card {
                background-color: #0c5ba2;
                .card-body {
                    .card-caption {
                        .card-subtitle {
                            color: #faba03;
                        }
                        .card-description {
                            ul {
                                li {
                                    &::before {
                                        background-image: url("/images/list-icon-debergen-tertiary.svg");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /* Eldorado */
        .item:nth-child(2) {
            .card {
                background-color: #002755;
                .card-body {
                    .card-caption {
                        .card-subtitle {
                            color: #e1aa12;
                        }
                        .card-description {
                            ul {
                                li {
                                    &::before {
                                        background-image: url("/images/list-icon-eldorado.svg");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /* Klein Canada */
        .item:nth-child(3) {
            .card {
                background-color: #a01814;
                .card-body {
                    .card-caption {
                        .card-subtitle {
                            color: #f68948;
                        }
                        .card-description {
                            ul {
                                li {
                                    &::before {
                                        background-image: url("/images/list-icon-kleincanada.svg");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /* De Spaendershorst */
        .item:nth-child(4) {
            .card {
                background-color: #a6bc04;
                .card-body {
                    .card-caption {
                        .card-subtitle {
                            color: #00671b;
                        }
                        .card-description {
                            ul {
                                li {
                                    &::before {
                                        background-image: url("/images/list-icon-despaendershorst.svg");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /* De Maasterp */
        .item:nth-child(5) {
            .card {
                background-color: #2f8f83;
                .card-body {
                    .card-caption {
                        .card-subtitle {
                            color: #e7ad4f;
                        }
                        .card-description {
                            ul {
                                li {
                                    &::before {
                                        background-image: url("/images/list-icon-demaasterp.svg");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        /* Hoeve De Schaaf */
        .item:nth-child(6) {
            .card {
                background-color: #305029;
                .card-body {
                    .card-caption {
                        .card-subtitle {
                            color: #fb7712;
                        }
                        .card-description {
                            ul {
                                li {
                                    &::before {
                                        background-image: url("/images/list-icon-hoevedeschaaf-tertiary.svg");
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

		/* Camping klein paradijs */
		.item:nth-child(7) {
			.card {
				background-color: #f0ba25;
				.card-body {
					.card-caption {
						.card-subtitle {
							color: #fff;
						}
						.card-description {
							ul {
								li {
									&::before {
										background-image: url("/images/list-icon-kleinparadijs.svg");
									}
								}
							}
						}
					}
				}
			}
		}

		/* Camping De Grote Altena */
		.item:nth-child(8) {
			.card {
				background-color: #273b3e;
				.card-body {
					.card-caption {
						.card-subtitle {
							color: #fff;
						}
						.card-description {
							ul {
								li {
									&::before {
										background-image: url("/images/list-icon-degrotealtena.svg");
									}
								}
							}
						}
					}
				}
			}
		}


        /* Camping Veerhuys */
		.item:nth-child(9) {
			.card {
				background-color: #b1ab3e;
				.card-body {
					.card-caption {
						.card-subtitle {
							color: #fff;
						}
						.card-description {
							ul {
								li {
									&::before {
										background-image: url("/images/list-icon-degrotealtena.svg");
									}
								}
							}
						}  
					}
				}
			}
		}
    }
}

/*  content
    ========================================================================== */
.content {
    margin: 6vh 0;
}

/*  content-secondary
    ========================================================================== */
.content-secondary {
    position: relative;
    margin: 6vh 0;
    padding: 6vh 0;
    background-color: $secondary;
    color: $white;
    &:after {
        content: "";
        z-index: 1;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 0;
        height: 0;
        margin: 0 auto;
        border-left: 24px solid transparent;
        border-right: 24px solid transparent;
        border-top: 32px solid $white;
    }
    h2 {
        color: $white;
        text-align: center;
    }
}

/*  content-blocks
    ========================================================================== */
.content-blocks {
    margin: 6vh 0;
    padding: 6vh 0;
}

/*  header-buttons-section
    ========================================================================== */
.header-buttons-section {
    .header-buttons.grid {
        margin: -35px -5px 6vh -5px;
        .item {
            &:first-of-type {
                .card {
                    background-color: $secondary;
                    color: $white;
                    .card-body {
                        .card-caption {
                            .card-title {
                                color: $white;
                            }
                        }
                    }
                    &:hover {
                        background-color: $tertiary;
                        color: $white;
                    }
                }
            }
        }
    }
}
&.main {
    .header-buttons.grid {
        .item {
            &:first-of-type {
                .card {
                    background-color: $tertiary;
                    &:hover {
                        background-color: $tertiary;
                    }
                    &:after {
                        border-top-color: $tertiary;
                    }
                }
            }
        }
    }
}
