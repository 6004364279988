/* ==========================================================================
   body
   ========================================================================== */
   $body-padding: 0;
   $body-sticky-padding: 0;

 
/* ==========================================================================
   containers
   ========================================================================== */
   $container-padding: 0 15px;
   $container-padding_sm: 0 15px;


/* ==========================================================================
   card
   ========================================================================== */

   $card-border: none;
   $card-base-border-radius: 0;

/* card > card-body
   ========================================================================== */
   $card-body-padding: 20px;

/* card > card-caption
   ========================================================================== */

   /* card > card-title */
   $card-title-font_size: 1.375rem; /* 22px */
   $card-title-text_transform: uppercase;
   $card-title-color: $gray-dark;

	/* card > card-subtitle */
	$card-subtitle-font_size: 0.8125rem; /* 13px */
   $card-subtitle-text_transform: uppercase;
   $card-subtitle-color: $tertiary;

   /* card > card-description */
   $card-description-max_height: auto;
   $card-description-margin: 0 0 15px 0;
   $card-description-font_size: 0.875rem; /* 14px */
   $card-description-color: $gray-dark;
   $card-description-fade-background: linear-gradient(180deg, rgba(#ffffff,0) 0%, rgba(#ffffff,1) 100%);


/* ==========================================================================
   gallery
   ========================================================================== */
   $gallery-margin: 0 -5px;
   $gallery-margin_sm: 0 -10px;
   
   $gallery-item-margin: 0 0 13px 0;
   $gallery-item-margin_sm: 0 0 13px 0;
   


/* ==========================================================================
   timeslot
   ========================================================================== */
   $timeslot-global-row-margin: 0 -15px -30px -15px !default;

/* timeslot > overview
   ========================================================================== */
   $timeslot-overview-margin: 0 0 30px 0 !default;
   
   $timeslot-overview-row-justify_content: flex-start !default;
   $timeslot-overview-row-margin: $timeslot-global-row-margin !default;
   
/* timeslot > category
   ========================================================================== */
   $timeslot-category-margin: 0 0 30px 0 !default;
   
   $timeslot-category-row-justify_content: flex-start !default;
   $timeslot-category-row-margin: $timeslot-global-row-margin !default;
   
   /* timeslot > category > content */
   $timeslot-category-content-margin: 0 0 30px 0 !default;
   
/* timeslot > category > title
   ========================================================================== */
   $timeslot-category-title-margin: 0 0 $headings-margin-bottom 0 !default;
   
   $timeslot-category-title-font_family: $headings-font-family !default;
   $timeslot-category-title-font_size: $h2-font-size !default;
   $timeslot-category-title-font_style: normal !default;
   $timeslot-category-title-font_weight: $headings-font-weight !default;
   $timeslot-category-title-line_height: $headings-line-height !default;
   $timeslot-category-title-text_transform: none !default;
   $timeslot-category-title-text_decoration: none !default;
   $timeslot-category-title-color: $body-color !default;
   
/* timeslot > highlight
   ========================================================================== */
   $timeslot-highlight-margin: 0 0 30px 0 !default;
   
   $timeslot-highlight-row-justify_content: flex-start !default;
   $timeslot-highlight-row-margin: $timeslot-global-row-margin !default;
   
/* timeslot > datepicker
   ========================================================================== */
   $timeslot-datepicker-padding: 0;
   
   $timeslot-datepicker-background: transparent;
   $timeslot-datepicker-border: none;
   
/* timeslot > datepicker > calendar
   ========================================================================== */
   $timeslot-datepicker-picker-padding: 10px;
   
   $timeslot-datepicker-picker-background: $white !default;
   $timeslot-datepicker-picker-border: none;
   $timeslot-datepicker-picker-border_radius: $border-radius !default;
   
   /* timeslot > datepicker > calendar > item */
   $timeslot-datepicker-calendar-item-background--active: $primary !default;
   $timeslot-datepicker-calendar-item-color--active: $white;
   
/* timeslot > item
   ========================================================================== */
   $timeslot-item-col: 12 !default;
   $timeslot-item-col_sm: 12 !default;
   $timeslot-item-col_md: 6 !default;
   $timeslot-item-col_lg: 6 !default;
   $timeslot-item-col_xl: 4 !default;
   
   $timeslot-item-margin: 0 0 30px 0 !default;
   $timeslot-item-padding: 0;
   
   $timeslot-item-background: transparent;
   $timeslot-item-border: none;
   $timeslot-item-border_radius: $border-radius !default;
   $timeslot-item-box_shadow: none !default;
   
/* timeslot > item > title
   ========================================================================== */
   $timeslot-item-title-margin: 0 0 $headings-margin-bottom 0 !default;
   
   $timeslot-item-title-font_family: $headings-font-family !default;
   $timeslot-item-title-font_size: $h4-font-size;
   $timeslot-item-title-font_style: normal !default;
   $timeslot-item-title-font_weight: $headings-font-weight !default;
   $timeslot-item-title-line_height: $headings-line-height !default;
   $timeslot-item-title-text_transform: uppercase;
   $timeslot-item-title-text_decoration: none !default;
   $timeslot-item-title-color: $white;
   
/* timeslot > item > navigation
   ========================================================================== */
   $timeslot-item-navigation-padding: 5px 0 !default;
   
   $timeslot-item-navigation-background: transparent !default;
   
   /* timeslot > item > navigation > btn */
   $timeslot-item-navigation-btn-padding: 5px 10px !default;
   
   $timeslot-item-navigation-btn-background_color: $primary !default;
   $timeslot-item-navigation-btn-background_color--hover: $secondary !default;
   $timeslot-item-navigation-btn-border: 1px solid $primary !default;
   $timeslot-item-navigation-btn-border--hover: 1px solid $secondary !default;
   $timeslot-item-navigation-btn-border_radius: $btn-border-radius !default;
   $timeslot-item-navigation-btn-box_shadow: none !default;
   
   $timeslot-item-navigation-btn-font_size: $font-size-base !default;
   $timeslot-item-navigation-btn-font_weight: $font-weight-bold !default;
   $timeslot-item-navigation-btn-line_height: $line-height-base !default;
   $timeslot-item-navigation-btn-color: $white !default;
   $timeslot-item-navigation-btn-color--hover: $white !default;
   
/* timeslot > item > days
   ========================================================================== */
   $timeslot-item-days-margin: -1px 0 0 0 !default;
   
   /* timeslot > item > day
      ========================================================================== */
   $timeslot-item-day-margin: 1px -.5px 0 -.5px !default;
   
   /* timeslot > item > day > holders */
   $timeslot-item-day-holders-padding: 0 .5px 0 .5px !default;
   
   $timeslot-item-day-holders-col: 12 !default;
   $timeslot-item-day-holders-col_sm: 6 !default;
   $timeslot-item-day-holders-col_md: 6 !default;
   $timeslot-item-day-holders-col_lg: 6 !default;
   $timeslot-item-day-holders-col_xl: 6 !default;
   
/* timeslot > item > day > title
   ========================================================================== */
   $timeslot-item-day-title-padding: 2px 0;
   
   $timeslot-item-day-title-background: transparent;
   
   $timeslot-item-day-title-font_weight: $font-weight-normal;
   $timeslot-item-day-title-color: $white;
   
/* timeslot > item > day > times
   ========================================================================== */
   $timeslot-item-day-times-padding: 2px 0;
   
   $timeslot-item-day-times-background: transparent;
   
   $timeslot-item-day-times-font_weight: $font-weight-bold;
   $timeslot-item-day-times-color: $white;
   
/* timeslot > item > day > times > info
   ========================================================================== */
   $timeslot-item-day-times-info-popup-top: 30px !default;
   $timeslot-item-day-times-info-popup-right: unset !default;
   $timeslot-item-day-times-info-popup-bottom: unset !default;
   $timeslot-item-day-times-info-popup-left: 50% !default;
   
   $timeslot-item-day-times-info-popup-width: 250px !default;
   $timeslot-item-day-times-info-popup-margin: 0 0 0 -125px !default;
   $timeslot-item-day-times-info-popup-padding: 15px !default;
   
   $timeslot-item-day-times-info-popup-background: #FFFFFF !default;
   $timeslot-item-day-times-info-popup-border: 1px solid #FFFFFF !default;
   $timeslot-item-day-times-info-popup-border_radius: $border-radius !default;
   $timeslot-item-day-times-info-popup-box_shadow: 0 3px 3px rgba(0, 0, 0, 0.1) !default;
   
   $timeslot-item-day-times-info-popup-font_size: $font-size-base * .875 !default;
   $timeslot-item-day-times-info-popup-line_height: $line-height-base !default;
   $timeslot-item-day-times-info-popup-color: $body-color !default;
   
   /* timeslot > item > day > times > info > arrow */
   $timeslot-item-day-times-info-arrow-top: unset !default;
   $timeslot-item-day-times-info-arrow-right: unset !default;
   $timeslot-item-day-times-info-arrow-bottom: 100% !default;
   $timeslot-item-day-times-info-arrow-left: 50% !default;
   
   $timeslot-item-day-times-info-arrow-margin: 0 0 0 -10px !default;
   
   $timeslot-item-day-times-info-arrow-border: 10px solid transparent !default;
   $timeslot-item-day-times-info-arrow-border_top_color: transparent !default;
   $timeslot-item-day-times-info-arrow-border_right_color: transparent !default;
   $timeslot-item-day-times-info-arrow-border_bottom_color: #FFFFFF !default;
   $timeslot-item-day-times-info-arrow-border_left_color: transparent !default;


/* ==========================================================================
   owl-carousel
   ========================================================================== */

   /* owl-carousel.slider > owl-dots */
   $slider-dots-button-background: transparent;
   $slider-dots-button-background--hover: $tertiary;
   $slider-dots-button-background--active: $tertiary;

   $slider-dots-button-border: 1px solid $tertiary;
   $slider-dots-button-border--hover: 1px solid $tertiary;
   $slider-dots-button-border--active: 1px solid $tertiary;
