.btn,
.card-btn {
    min-width: 175px;
    padding: 0.475rem 1rem;
    border-radius: 2px;
    border-color: $tertiary;
    background-color: $tertiary;
    box-shadow: none;
    color: $white;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: $font-family-base;
    text-decoration: none;
    &:hover {
        background-color: lighten($tertiary, 10%);
        border-color: lighten($tertiary, 10%);
    }

    /* btn-outline-primary */
    &.btn-outline-primary {
        border-color: $tertiary;
        background-color: transparent;
        color: $tertiary;
        &:hover {
            background-color: $tertiary;
            color: $white;
        }
    }

    /* btn-secondary */
    &.btn-secondary {
        border-color: $secondary;
        background-color: $secondary;
        color: $white;
        &:hover {
            background-color: lighten($secondary, 10%);
            border-color: lighten($secondary, 10%);
        }
    }

    /* btn-outline-secondary */
    &.btn-outline-secondary {
        border-color: $secondary;
        background-color: transparent;
        color: $secondary;
        &:hover {
            background-color: $secondary;
            color: $white;
        }
    }

    /* btn-tertiary */
    &.btn-tertiary {
        border-color: $tertiary;
        background-color: $tertiary;
        color: $white;
        &:hover {
            background-color: lighten($tertiary, 10%);
            border-color: lighten($tertiary, 10%);
        }
    }

    /* btn-white */
    &.btn-white {
        border-color: $white;
        background-color: $white;
        color: $gray-dark;
        &:hover {
            background-color: darken($white, 10%);
            border-color: darken($white, 10%);
        }
    }
}

/* btn-transparent */
.btn-transparent {
    background-color: rgba($white, 0.2);
    border-color: transparent;
    &:hover {
        background-color: lighten($tertiary, 10%);
        border-color: lighten($tertiary, 10%);
    }
}

/* btn-play-icon */
.btn-play-icon {
	&:after {
		content: "\f04b";
		font-family: $font-family-awesome;
		margin-left: 10px;
		font-size: 13px;
		font-weight: 900;
	}
}



/*  btn-back
    ========================================================================== */
    .btn-back {
        display: inline-block;
        font-size: 14px;
        text-transform: uppercase;
        &:before {
            content: "\f104";
            float: left;
            margin-right: 5px;
            font-weight: 900;
        }
    }
