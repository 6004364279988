/*  default
    ========================================================================== */
    .card {
        .card-body {
            @include media-breakpoint-up(xl) {
                padding: 30px;
            }
            .card-caption {
                display: flex;
                flex-direction: column;
                .card-title-link {
                    order: 1;
                }
                .card-subtitle {
                    order: 0;
                }
                .card-prices {
                    order: 2;
                }
                .card-description {
                    order: 3;
                    &:after {
                        @extend .d-none;
                    }
                }
            }
            .card-buttons {
                .card-btn {
                    width: 100%;
                }
            }
        }
    }


/*  bg-dark > card
    ========================================================================== */
    .bg-dark {
        .card {
            .card-title-link,
            .card-title {
                color: $gray-dark !important;
            }
        }
        .card.card-overlay {
            .card-image {
                overflow: hidden;
                .card-img {
                    transition: 0.5s ease-in-out;
                }
            }
            .card-img-overlay {
                display: flex;
                padding: 20px;
                justify-content: flex-end;
                background-color: rgba($black, 0.15);
                .card-caption {
                    flex: 0;
                    .card-title-link,
                    .card-title {
                        color: $white !important;
                        text-shadow: none;
                    }
                    .card-subtitle {
                        text-shadow: none;
                    }
                    .card-description {
                        @extend .d-none;
                    }
                }
                .card-buttons {
                    .card-btn {
                        @extend .btn-tertiary;
                    }
                }
            }
            &:hover {
                .card-img {
                    transform: scale(1.05);
                }
            }
        }
    }
    