&.vacancy_vacancy_overview,
&.vacancy_overview {
    .content-secondary {
        @include media-breakpoint-only(xs) {
            padding-left: 5px;
            padding-right: 5px;
        }
        @include media-breakpoint-only(sm) {
            padding-left: 10px;
            padding-right: 10px;
        }
        @include media-breakpoint-only(xl) {
            padding-left: 25px;
            padding-right: 25px;
        }
        > .container {
            display: flex;
            justify-content: center;
        }
        .container-fluid {
            @include media-breakpoint-down(sm) {
                padding-left: 0;
                padding-right: 0;
            }
        }
        .grid {
            @include media-breakpoint-only(xs) {
                margin: 0 0 -5px 0;
            }
            @include media-breakpoint-only(sm) {
                margin: 0 -5px -10px -5px;
            }
            @include media-breakpoint-only(xl) {
                margin: 0 -20px -40px -20px;
            }
            .item {
                @include media-breakpoint-only(xs) {
                    margin-bottom: 5px;
                    padding-left: 0;
                    padding-right: 0;
                }
                @include media-breakpoint-only(sm) {
                    margin-bottom: 10px;
                    padding-left: 5px;
                    padding-right: 5px;
                }
                @include media-breakpoint-only(xl) {
                    margin-bottom: 40px;
                    padding-left: 20px;
                    padding-right: 20px;
                }
                @include media-breakpoint-up(lg) {
                    @include make-col(4);
                }
                .card {
                    .card-image {
                        .card-image-link {
                            .card-image-caption {
                                z-index: 1;
                                display: flex;
                                align-items: center;
                                left: auto;
                                right: 15px;
                                bottom: -15px;
                                width: 100px;
                                height: 100px;
                                background-color: $primary;
                                border-radius: 100%;
                                overflow: hidden;
                                .card-image-label {
                                    background: none;
                                    font-weight: 700;
                                    text-overflow: unset;
                                    text-transform: uppercase;
                                    transform: rotate(5deg);
                                    transition: 0.5s;
                                }
                            }
                            .card-img {
                                transition: 0.5s;
                            }
                            &:hover {
                                .card-image-label {
                                    transform: rotate(0deg);
                                }
                                .card-img {
                                    opacity: 0.8;
                                }
                            }
                        }
                    }
                    .card-body {
                        .card-caption {
                            .category-title {
                                @extend .card-subtitle;
                                color: $black;
                            }
                            .card-prices {
                                margin-bottom: 15px;
                                text-align: right;
                                .card-price {
                                    color: $gray-dark;
                                }
                            }
                        }
                        .card-buttons {
                            .card-btn {
                                width: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    .mini-filter {
        margin-bottom: 6vh;
        background-color: rgba($white, 0.5);
    }
}

&.vacancy_vacancy_detail,
&.vacancy_detail {
    .content {
        > .container-fluid {
            padding: 0 !important;
        }
    }

    /* info */
    .info {
        margin-bottom: 6vh;
        text-align: center;
    }

    /* gallery */
    .gallery {
        margin-bottom: 6vh;
        @include media-breakpoint-up(xl) {
            padding: 0 25px;
        }
    }
}
