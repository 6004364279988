.mini-sab {   
    position: relative;
    z-index: 100;
    @include media-breakpoint-up(lg) {
        height: 0;
        padding: 0;
    }
    .container {
        position: relative;
        padding: 0;
        @extend .d-flex;
        @extend .justify-content-center;
    }
    .container-holder {
        padding: 0 15px 15px 15px;
        @include media-breakpoint-up(lg) {
            position: absolute;
            left: auto;
            right: auto;
            bottom: 20px;
            min-height: 120px;
            background-color: $white;
            border-radius: 8px;
        }

        /* header-buttons */
        .header-buttons {
            margin: -60px -5px 30px -5px;
            .item {
                &:first-of-type {
                    .card {
                        background-color: $secondary;
                        color: $white;
                        &:after {
                            content: "";
                            z-index: 1;
                            position: absolute;
                            top: 100%;
                            left: 0;
                            right: 0;
                            width: 0;
                            height: 0;
                            margin: 0 auto;
                            border-left: 15px solid transparent;
                            border-right: 15px solid transparent;
                            border-top: 20px solid $secondary;
                        }
                        .card-body {
                            .card-caption {
                                .card-title {
                                    color: $white;
                                }
                            }
                        }
                        &:hover {
                            background-color: $secondary;
                            color: $white;
                        }
                    }
                }
            }
        }

        /* logo */
        .logo {
            @include media-breakpoint-down(lg) {
                display: none;
            }
            position: absolute;
            left: 100%;
            bottom: 100%;
            @include media-breakpoint-up(lg) {
                width: 200px;
                margin: 0 0 -45px -45px;
            }
            a {
                @extend .d-block;
                img {
                    width: 100%;
                }
            }
        }

    }
}