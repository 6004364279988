.header {
    position: sticky;

    // header-socket
    .header-socket {
        padding: 0 15px;
        background-color: $white;
        @include media-breakpoint-up(xl) {
            padding: 0 40px;
        }
        .container-navbar {
            .column.navbar {
                justify-content: center !important; // overrule
                min-height: 90px;
                padding: 0;

                // logo
                .logo {
                    max-width: 250px;
                    @include media-breakpoint-up(md) {
                        max-width: 275px;
                    }
                    @include media-breakpoint-up(lg) {
                        max-width: 300px;
                    }
                    @include media-breakpoint-up(xl) {
                        margin-right: auto;
                    }
                    transition: 0.5s;
                    a {
                        display: block;
                    }
                }

                // menu
                .menu {
                    @include media-breakpoint-down(lg) {
                        display: none !important;
                    }
                    margin: 0 30px auto 0;
                    flex-basis: auto;
                    flex-grow: 0;
                    .navbar-nav {
                        .nav-item {
                            margin: 0 1px;
                            padding: 5px 0;
                            font-size: 12px;
                            font-weight: 500;
                            letter-spacing: 0.6px;
                            text-transform: uppercase;
                            .nav-link {
                                color: $black;
                                &:hover {
                                    color: $primary;
                                }
                            }
                            &.dropdown {
                                &:hover {
                                    .dropdown-menu {
                                        display: block;
                                    }
                                }
                            }
                            @include media-breakpoint-up(xl) {
                                .dropdown-menu {
                                    min-width: 230px;
                                    margin-top: 0;
                                    margin-left: -115px;
                                    left: 50%;
                                    padding: 15px 30px;
                                    border-color: $gray-dark;
                                    border-radius: 0;
                                    &:before {
                                        content: "";
                                        z-index: 1;
                                        position: absolute;
                                        bottom: 100%;
                                        left: 0;
                                        right: 0;
                                        width: 0;
                                        height: 0;
                                        margin: 0 auto;
                                        border-left: 10px solid transparent;
                                        border-right: 10px solid transparent;
                                        border-bottom: 15px solid $gray-dark;
                                    }
                                    &:after {
                                        content: "";
                                        z-index: 1;
                                        position: absolute;
                                        bottom: 100%;
                                        left: 0;
                                        right: 0;
                                        width: 0;
                                        height: 0;
                                        margin: 0 auto;
                                        border-left: 9px solid transparent;
                                        border-right: 9px solid transparent;
                                        border-bottom: 13px solid $white;
                                    }
                                    .nav-item {
                                        margin: 0;
                                        padding-bottom: 0;
                                        //font-size: 14px;
                                        font-weight: 500;
                                        line-height: 1;
                                        white-space: nowrap;
                                        .nav-link {
                                            padding-left: 0;
                                            padding-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &.collapse:not(.show) {
                        @include media-breakpoint-down(lg) {
                            display: none !important;
                        }
                    }
                }

                // language
                .site_switcher {
                    @include media-breakpoint-down(lg) {
                        position: absolute;
                        top: 0;
                        right: 0;
                    }
                    margin: 10px 0 auto 0;
                    .language {
                        .nav-item {
                            + li {
                                margin-left: 5px;
                            }
                            .nav-link {
                                padding: 0;
                                border-radius: 100%;
                                opacity: 0.5;
                                img {
                                    display: block;
                                    width: 20px;
                                    height: 20px;
                                    border-radius: 100%;
                                }
                                &:hover {
                                    opacity: 1;
                                }
                            }
                            &.active {
                                .nav-link {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    // header-main
    .header-main {
        position: relative;
        padding: 0;
        background-color: $primary;
        &:after {
            content: "";
            z-index: 1;
            position: absolute;
            top: 55px;
            left: 0;
            right: 0;
            width: 0;
            height: 0;
            margin: 0 auto;
            border-left: 24px solid transparent;
            border-right: 24px solid transparent;
            border-top: 32px solid $primary;
        }
        .container-navbar {
            .column.navbar {
                justify-content: center !important; // overrule
                min-height: 55px;
                padding: 0;
                color: $white;

                // navbar-toggler
                .navbar-toggler {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    left: 0;
                    min-width: 55px;
                    height: 55px;
                    margin: 0;
                    padding: 0 15px;
                    @include media-breakpoint-up(xl) {
                        padding: 0 40px;
                    }
                    border: none;
                    border-right: 2px solid $white;
                    border-radius: 0;
                    background-color: $tertiary;
                    outline: none;
                    color: $white;
                    font-size: 18px;
                    font-weight: 700;
                    text-transform: uppercase;
                    transition: 0.5s;
                    &[aria-expanded="true"] {
                        .fa-bars {
                            &::before {
                                content: "\f00d";
                            }
                        }
                    }
                    &:hover {
                        background-color: $primary;
                        color: $white;
                    }
                    .navbar-toggler-icon {
                        width: 16px;
                    }
                    .navbar-toggler-label {
                        margin-left: 10px;
                        line-height: 19px;
                        @include media-breakpoint-down(lg) {
                            display: none;
                        }
                    }
                }

                // park-slogan
                .park-slogan {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 55px;
                    margin: 0 auto;
                    letter-spacing: normal;
                    @include media-breakpoint-down(xs) {
                        padding: 0 15px 0 70px;
                    }
                    color: $white;
                    font-size: 14px;
                    line-height: 1.2;
                    text-align: center;
                    @include media-breakpoint-up(lg) {
                        font-size: 16px;
                    }
                    @include media-breakpoint-up(xl) {
                        font-size: 18px;
                    }
                    p {
                        margin-bottom: 0;
                    }
                    a {
                        color: $white;
                        text-decoration: none;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }

                // menu-icons-bottom
                .menu-icons-bottom {
                    @include media-breakpoint-up(xl) {
                        display: none !important;
                    }
                    &.show {
                        display: flex !important;
                    }
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    padding: 25px 0;
                    background-color: $tertiary;
                    .icon {
                        margin: 0 25px;
                        text-align: center;
                        .icon-link {
                            display: block;
                            color: $white;
                            font-size: 12px;
                            font-weight: 700;
                            text-decoration: none;
                            text-transform: uppercase;
                            i {
                                display: block;
                                margin-bottom: 10px;
                                font-size: 40px;
                            }
                            img {
                                margin: 5px;
                            }
                            &:hover {
                                color: $black;
                            }
                        }
                    }
                }

                // menu
                .menu {
                    @include media-breakpoint-up(xl) {
                        height: 0;
                        overflow: hidden;
                    }
                    &.show {
                        height: auto;
                    }
                    width: 100%;
                    background-color: $primary;
                    .navbar-nav {
                        flex-wrap: wrap;
                        justify-content: center;
                        margin: 15px auto;
                        @include media-breakpoint-up(xl) {
                            margin-top: 45px;
                        }
                        > .nav-item {
                            margin: 0 15px;
                            @include media-breakpoint-up(xl) {
                                margin-bottom: 30px;
                                font-size: 24px;
                                font-family: $font-family-lobster;
                                > .nav-link {
                                    cursor: default;
                                    text-decoration: none !important;
                                }
                            }
                        }
                        .nav-item {
                            @include media-breakpoint-down(lg) {
                                border-top: 1px solid rgba($white, 0.3);
                                &:first-of-type {
                                    border-top: none;
                                }
                                font-weight: 400;
                            }
                            .nav-link {
                                @include media-breakpoint-up(xl) {
                                    padding-top: 0;
                                    padding-bottom: 0;
                                }
                                color: $white;
                                &:hover {
                                    text-decoration: underline;
                                }
                            }
                        }
                        .dropdown-menu {
                            margin: 0 0 0 15px;
                            padding: 0;
                            background: none;
                            border: none;
                            border-radius: 0;
                            @include media-breakpoint-down(lg) {
                                border-top: 1px solid rgba($white, 0.3);
                            }
                            @include media-breakpoint-up(xl) {
                                margin: 10px 0 0 0 !important;
                            }
                            .nav-link {
                                font-weight: 400;
                                font-family: $font-family-base;
                            }
                        }
                        @include media-breakpoint-up(xl) {
                            .dropdown-toggle::after {
                                display: none;
                            }
                            .dropdown-menu {
                                display: block;
                                position: static;
                                margin: 0;
                                padding: 0;
                                background: none;
                                border: none;
                            }
                        }
                    }
                }

                #top_menu {
                    @include media-breakpoint-up(xl) {
                        display: none !important;
                    }
                    background-color: #fff;
                    .navbar-nav {
                        .nav-item {
                            border-top-color: rgba($black, 0.3);
                            .nav-link {
                                color: $black;
                            }
                        }
                        .dropdown-menu {
                            border-top-color: rgba($black, 0.3);
                        }
                    }
                }
            }
        }
    }

    // sticky
    &.sticky {
        position: sticky;
        box-shadow: none;
    }
}

// main
&.main {
    .header {
        .navbar-toggler {
            @include media-breakpoint-up(xl) {
                display: none !important;
            }
        }
    }
}
&.main {
    .header {
        .header-socket {
            .container-navbar {
                .column.navbar {
                    .menu {
                        margin-bottom: 0;
                        .navbar-nav {
                            .nav-item {
                                font-size: 15px;
                                font-weight: 700;
                            }
                        }
                    }
                }
            }
        }
    }
}
