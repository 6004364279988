& {
	padding: $body-padding;
	letter-spacing: 0.7px;

	&::after {
		content: "";
		background-color: rgba(0, 0, 0, 0);
		transition: background-color 0.5s;
	}

	&.nav-overlay-active::after {
		content: "";
		z-index: 998;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
	}
}

/*  base
    ========================================================================== */

/* headings */
h1,
h2,
h3 {
	font-weight: 400;
	font-family: $font-family-lobster;
	letter-spacing: normal;
}

h4,
h5,
h6 {
	text-transform: uppercase;
}

h1 {
	@include media-breakpoint-down(lg) {
		font-size: $h1-font-size-mobile;
	}
}

h2 {
	@include media-breakpoint-down(lg) {
		font-size: $h2-font-size-mobile;
	}
}

&.home h2 {
	font-size: $h1-font-size;
	@include media-breakpoint-down(lg) {
		font-size: $h1-font-size-mobile;
	}
}

h3 {
	@include media-breakpoint-down(lg) {
		font-size: $h3-font-size-mobile;
	}
}

&.home h3 {
	font-size: $h2-font-size;
	@include media-breakpoint-down(lg) {
		font-size: $h2-font-size-mobile;
	}
}

/* img */
img {
	max-width: 100%;
	height: auto;
}

/* transition */
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

/* forms */
.form-control {
	border-radius: 2px;
}

/* header-buttons */
.header-buttons.grid {
	z-index: 100;
	position: relative;
	flex-wrap: nowrap;

	.item {
		flex: 1;
		max-width: 270px;
		margin-bottom: 0;
		padding-left: 5px;
		padding-right: 5px;
		@include media-breakpoint-down(sm) {
			max-width: 120px;
		}

		.card {
			position: relative;
			flex-direction: row;
			@include media-breakpoint-down(sm) {
				flex-direction: column;
			}
			align-items: center;
			min-height: 70px;
			background-color: $gray-light;
			color: $black;
			text-decoration: none;
			overflow: unset;

			.card-image {
				font-size: unset;
				background: transparent;
				transition: 0.5s;
				@include media-breakpoint-down(sm) {
					margin-top: 10px;
				}
			}

			.card-body {
				padding: 10px 20px 10px 0;
				@include media-breakpoint-down(sm) {
					padding: 10px;
				}
				background: transparent;
				overflow: hidden;

				.card-caption {
					text-align: center;

					.card-title {
						margin-bottom: 0;
						color: unset;
						font-size: 13px;
						font-weight: 700;
						transition: unset;
						@include media-breakpoint-down(sm) {
							font-size: 12px;
							text-align: center;
						}
					}
				}
			}

			&:hover {
				background-color: $tertiary;
				color: $white;
			}
		}
	}
}

/* mini-filter */
.mini-filter {
	margin-bottom: 30px;
	padding: 15px 15px 0 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px 30px 1rem 30px;
	}
	background-color: $white;
	color: $black;
	border-radius: 8px;
}

/* accommodation_search_book */
&.accommodation_search_book {
	.search_book {
		.w3-filter-collection {
			max-width: 1110px;
			margin: 0 auto;
		}
	}
}


/* CUSTOM FILE OVERWRITE */

.custom-file-input {
	opacity: 1;
}
