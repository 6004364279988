&.blog_post_detail {
	
	.info {
		margin-bottom: 3vh;
		text-align: center;
	}

	.gallery {
		margin-top: 6vh;
		margin-bottom: 6vh;
	}

	.content {
		> .container-fluid {
			padding: 0 !important;
		}
	}

}