/* ul */
ul.custom-list {
    @extend .list-unstyled;
    li {
        position: relative;
        padding: 0 0 0 25px;
        &::before {
            content: '\f00c';
            position: absolute;
            top: 0;
            left: 0;
            font-family: 'Font Awesome 5 Pro';
            color: $secondary;
            font-weight: 900;
        }
    }
}

/* ol */
ol.custom-list {
    @extend .list-unstyled;
    li {
        position: relative;
        padding: 0 0 0 25px;
        &::before {
            content: '';
            position: absolute;
            top: 6px;
            left: 0;
            display: block;
            width: 14px;
            height: 12px;
            background-image: url('/images/list-icon.svg');
            background-size: cover;
        }
    }
}
&.park-de-bergen {
    ol.custom-list {
        li {
            &::before {
                background-image: url('/images/list-icon-debergen.svg');
            }
        }
    }
}
&.park-eldorado {
    ol.custom-list {
        li {
            &::before {
                background-image: url('/images/list-icon-eldorado.svg');
            }
        }
    }
}
&.park-klein-canada	 {
    ol.custom-list {
        li {
            &::before {
                background-image: url('/images/list-icon-kleincanada.svg');
            }
        }
    }
}
&.park-de-spaendershorst {
    ol.custom-list {
        li {
            &::before {
                background-image: url('/images/list-icon-despaendershorst.svg');
            }
        }
    }
}
&.park-de-maasterp {
    ol.custom-list {
        li {
            &::before {
                background-image: url('/images/list-icon-demaasterp.svg');
            }
        }
    }
}
&.park-hoeve-de-schaaf {
    ol.custom-list {
        li {
            &::before {
                background-image: url('/images/list-icon-hoevedeschaaf.svg');
            }
        }
    }
}
&.park-klein-paradijs {
    ol.custom-list {
        li {
            &::before {
                background-image: url('/images/list-icon-kleinparadijs.svg');
            }
        }
    }
}
&.park-de-grote-altena {
    ol.custom-list {
        li {
            &::before {
                background-image: url('/images/list-icon-degrotealtena.svg');
            }
        }
    }
}
&.caravan {
    ol.custom-list {
        li {
            &::before {
                background-image: url('/images/list-icon-caravan.svg');
            }
        }
    }
}
&.adventure {
    ol.custom-list {
        li {
            &::before {
                background-image: url('/images/list-icon-adventure.svg');
            }
        }
    }
}
