.eyecatcher {
	background-color: rgba($primary, 0.1);

	.container-fluid { max-width: none;}

	// vimeo_video
	.vimeo_video {
		position: relative;
		padding-top: 56.25%;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	// video-title
	.video-title {
		z-index: 100;
		position: absolute;
		top: 0;
		left: 15px;
		right: 15px;
		bottom: 0;
		display: flex;
		align-items: center;
		flex-direction: column;
		justify-content: center;
		max-width: 730px;
		margin: 0 auto;
		@include media-breakpoint-up(lg) {
			margin-bottom: 10vw;
		}
		color: $white;
		font-size: 30px;
		font-weight: 400;
		line-height: 1;
		text-align: center;
		font-family: $font-family-lobster;
		@include media-breakpoint-up(md) {
			font-size: 50px;
		}
		@include media-breakpoint-up(xl) {
			font-size: 90px;
		}
		b,
		span {
			color: $tertiary;
			font-weight: 400;
			text-shadow: 2px 2px 0 $white;
		}
	}

	// owl-carousel
	.owl-carousel {
		.item {
			.owl-caption {
				position: relative;
				max-width: 700px;
				margin: 0 auto;
                color: $white;
				text-align: center;
				letter-spacing: normal;
				@include media-breakpoint-up(lg) {
					margin-bottom: 10vw;
				}
				.owl-title {
					margin-bottom: 0;
					font-size: 50px;
					line-height: 1;
					font-weight: 400;
					text-shadow: none;
					font-family: $font-family-lobster;
					@include media-breakpoint-up(xl) {
                        font-size: 90px;
					}
					b,
					span {
						color: $tertiary;
						font-weight: 400;
						text-shadow: 2px 2px 0 $white;
					}
				}
				.owl-btn {
					margin-top: 30px;
				}
			}
		}
		.owl-dots,
		.owl-nav {
			@extend .d-none;
		}
	}
}

&.home {
	.eyecatcher {
		min-height: auto;
		max-height: 70vh;
		overflow: hidden;
		.owl-carousel {
			.item {
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background-color: rgba($black, 0.15);
				}
			}
		}
	}
}
