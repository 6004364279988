html {
	font-size: initial;
}

body {
	font-size: $font-size-base;
}

#TommyBookingSupport {
	a {
		text-decoration: none;
	}

	h4 {
		text-transform: none;
		color: $black;
		font-weight: 400;
	}

	.tbs-container-fluid {
		@extend .px-0;
	}

	.tbs-navbar-collapse {
		@extend .px-0;
	}

	.tbs-nav {
		li {
			font-size: 14px;
		}
	}

	.tbs-page-overzicht {
		#coupon_code {
			h2 {
				margin-top: 20px;
			}
		}

		label[data-trn-key^="__akkoord_algemene_voorwaarden__"] {
			a {
				text-decoration: underline;
			}
		}
	}
}
