&.timeslot_overview {

    .timeslot_overview {
        color: $body-color;
        .timeslot-datepicker {
            .datepicker-switch {
                color: $tertiary;
                font-weight: 700;
                text-transform: uppercase;
            }
            .prev,
            .next {
                background-color: $tertiary;
                color: $white;
            }
            .dow {
                color: $primary;
                font-size: 20px;
                font-weight: 400;
                font-family: $font-family-lobster;
            }
        }
        .timeslot-item {
            color: $white;
            &-navigation {
                &-btn {
                    min-width: auto;
                }
                &-duration {
                    padding: 0;
                    color: $tertiary;
                    font-size: 13px;
                    font-weight: 700;
                    text-transform: uppercase;
                }
            }
        }
    }
    
}