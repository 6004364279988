&.service_category,
&.service_overview,
&.service_detail {

	/* info */
	.info {
		margin-bottom: 6vh;
		text-align: center;
	}

	/* gallery */
	.gallery {
		margin-top: 6vh;
		margin-bottom: 6vh;
	}

	/* icons-list */
	.icons-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 6vh;
		margin-bottom: 6vh;
		.icons-list-item {
			flex: 1;
			max-width: 130px;
			.icons-list-item-icon {
				width: 100px;
				margin: 0 auto;
				background-color: var(--w3-secondary-color);
			}
			.icons-list-item-label {
				line-height: 1em;
				text-align: center;
			}
		}
	}

	.descriptions {
		margin-top: 6vh;
		margin-bottom: 6vh;
		@include make-row();

		.description-column {
			@include make-col(12);

			@include media-breakpoint-up(sm) {
				@include make-col(6);

				&.column-left {
					padding-right: 25px;
				}

				&.column-right {
					padding-left: 25px;
				}
			}
		}
	}

}

&.service_category,
&.service_detail {

	.content {
		> .container-fluid {
			padding: 0 !important;
		}
	}

}

&.service_detail {

	.content-secondary {
		@include media-breakpoint-only(xs) {
			margin-left: -5px;
			margin-right: -5px;
		}
		@include media-breakpoint-up(sm) {
			margin-left: -10px;
			margin-right: -10px;
		}
		@include media-breakpoint-up(xl) {
			margin-left: -25px;
			margin-right: -25px;
		}
		padding: 8vh 0 6vh 0;
		.service-review {
			max-width: 360px;
			margin: 0 auto;
			font-size: 18px;
			font-weight: 700;
			text-align: center;
			&::before {
				content: "\f4b0";
				font-family: $font-family-awesome;
				color: $tertiary;
				font-size: 50px;
				font-weight: 300;
			}
			h5 {
				margin-bottom: 30px;
				color: $white;
				font-size: 24px;
				font-weight: 400;
				text-transform: none;
				font-family: $font-family-lobster;
			}
		}
	}

	.slider {
		.other-service-item {
			.card-image {
				overflow: hidden;
				.card-img {
					transition: 0.5s ease-in-out;
				}
			}
			.card-body {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				display: flex;
				flex-direction: column;
				justify-content: flex-end;
				padding: 10px 15px;
				background-color: rgba($black, 0.20);
				.card-caption {
					flex: 0;
					.card-title-link,
					.card-title {
						margin-bottom: 0;
						color: $white;
						font-size: 18px;
						font-weight: 700;
						letter-spacing: 0.7px;
						text-transform: uppercase;
						font-family: $font-family-dm-sans;
					}
					.card-subtitle,
					.card-description {
						@extend .d-none;
					}
				}
				.card-buttons {
					@extend .d-none;
				}
			}
			&:hover {
				.card-img {
					transform: scale(1.05);
				}
			}
		}
	}

}
