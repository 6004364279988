&.landing_page {

    .bg-dark {
        margin: 0;
        padding: 6vh 0;
        color: $white;
        h1, h2, h3, h4, h5, h6 {
            color: $white;
        }
        a:not(.btn) {
            color: $white;
            &:hover {
                color: $tertiary;
            }
        }
    }

}
