@mixin defineColorHSLA($color-name, $value) {
    $hue: hue($value);
    $saturation: saturation($value);
    $lightness: lightness($value);
    $alpha: alpha($value);

    #{$color-name}: unquote("hsla(#{$hue}, #{$saturation}, #{$lightness}, #{$alpha})");
    #{$color-name}-hsla: $hue, $saturation, $lightness, $alpha;
    #{$color-name}-h: $hue;
    #{$color-name}-s: $saturation;
    #{$color-name}-l: $lightness;
    #{$color-name}-a: $alpha;
}

:root {
    /* default colors */
    @include defineColorHSLA(--w3-primary-color, #1555a2);
    @include defineColorHSLA(--w3-secondary-color, #468825);
    @include defineColorHSLA(--w3-tertiary-color, #e28c06);
}

/*  bootstrap > theme-colors
    ========================================================================== */
$primary: var(--w3-primary-color);
$secondary: var(--w3-secondary-color);
$tertiary: var(--w3-tertiary-color);
$dark: $secondary;

/*  bootstrap > colors
    ========================================================================== */
$gray: #707070;
$gray-light: #ebebeb;
$gray-dark: #425569;

/*  bootstrap > body
    ========================================================================== */
$link-color: $primary; // default
$link-decoration: underline; // default

/*  bootstrap > font
    ========================================================================== */
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Lobster&display=swap");

$font-family-dm-sans: "DM Sans", sans-serif;
$font-family-lobster: "Lobster", cursive;
$font-family-awesome: "Font Awesome 5 Pro";
$font-family-base: $font-family-dm-sans;

/* bootstrap > typography
    ========================================================================== */
$font-size-base: 1rem; // 16px
$font-weight-base: 500;

$h1-font-size: $font-size-base * 3; // 48px
$h2-font-size: $font-size-base * 2.25; // 36px
$h3-font-size: $font-size-base * 1.875; // 30px
$h4-font-size: $font-size-base * 1.375; // 22px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base; // 16px

$h1-font-size-mobile: $font-size-base * 2.25; // 36px
$h2-font-size-mobile: $font-size-base * 1.875; // 30px
$h3-font-size-mobile: $font-size-base * 1.5; // 24px

$headings-color: $primary;
$headings-font-weight: 700;

/*  overrule
    ========================================================================== */

/* main - home */
body.main.home {
    .highlights-section {
        .grid {
            .item {
                .card {
                    .card-image {
                        background-color: $tertiary;
                    }
                    &:hover {
                        .card-image {
                            background-color: $primary;
                        }
                    }
                }
            }
        }
    }
}
